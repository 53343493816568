module.exports = {
  siteTitle: 'Obeneo Oy', // <title>
  manifestName: 'Bluescale',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/`, // This path is subpath of your hosting https://domain/portfolio
  heading: 'obeneo',
  subHeading: 'software and security - through digital narrows',

  // social
  socialLinks: [
   /*
    {
      icon: 'fa-github',
      name: 'Github',
      url: 'https://github.com/obeneo',
    },
    {
      icon: 'fa-linkedin-in',
      name: 'Linkedin',
      url: 'https://linkedin.com/in/theanubhav/',
    },
    {
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/onlyanubhav',
    },
    {
      icon: 'fa-facebook-f',
      name: 'Facebook',
      url: 'https://facebook.com/theanubhav',
    },
    */
  ],
  business_id: '3137552-6',
  email_ville: 'ville.ilvonen@obeneo.com',
  email_ilona: 'ilona.ilvonen@obeneo.com',
  phone_ville: '+358 50 486 7795',
  phone_ilona: '+358 40 594 0479',
  address: 'Tampere, Finland',
};
